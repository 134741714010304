<template>
<div class="fixture-container">
	<!-- Navegación de tabs con iconos -->
	<nav class="tabs-navigation">
	<div class="tabs-container">
		<button
		v-for="(tab, index) in tabs"
		:key="index"
		:class="['tab-button', { active: selectedTab === index }]"
		@click="selectedTab = index"
		>
		<span class="tab-icon">{{ getTabIcon(index) }}</span>
		{{ tab }}
		</button>
	</div>
	</nav>

	<!-- Tab Contents -->
	<div class="content-wrapper">
	<v-window v-model="selectedTab" class="tab-content">
		<v-window-item :value="0">


		<div class="groups-container">
			<v-row v-for="(group, index) in groups" :key="index" class="group-section">
			<v-col cols="12">
				<v-card class="group-card" elevation="2">
				<v-card-title class="group-header">
					<v-icon icon="mdi-account-group" class="mr-2"></v-icon>
					Grupo {{ index + 1 }}
				</v-card-title>
				
				<v-card-text class="pa-0">
					<v-data-table
					:headers="headers"
					:items="group"
					class="standings-table"
					:hover="true"
					:items-per-page="-1"
					hide-default-footer
					>
					<template v-slot:item="{ item }">
						<tr :class="{ 'qualified-row': classified.includes(item.id_team) }">
						<td class="team-cell">
							<div class="team-info">
							<img 
								v-if="item.logo" 
								:src="item.logo" 
								:alt="item.name"
								class="team-logo"
							/>
							<v-icon v-else icon="mdi-shield" class="team-icon"></v-icon>
							<span class="team-name">{{ item.name }}</span>
							</div>
						</td>
						<td class="points-cell">{{ item.points }}</td>
						<td>{{ item.stats?.pj || 0 }}</td>
						<td>{{ item.stats?.partidos_ganados || 0 }}</td>
						<td>{{ item.stats?.partidos_empatados || 0 }}</td>
						<td>{{ item.stats?.partidos_perdidos || 0 }}</td>
						<td class="hide-sm">{{ item.stats?.goles || 0 }}</td>
						<td class="hide-sm">{{ item.stats?.goles_contra || 0 }}</td>
						<td>{{ calculateDifference(item.stats) }}</td>
						</tr>
					</template>
					</v-data-table>
				</v-card-text>
				</v-card>
			</v-col>
			</v-row>
		</div>

			<FixturePlayoff
				v-if="this.phase_2 == 'playoff' &&  this.matches_phase_2 && 
							Object.keys(this.matches_phase_2).length > 0"
				:matches="matches_phase_2"
				:idT="idT"
				:info="info"
				:champion="champion"
				class="mt-6"
			/>
			<LeagueTableIsolated
				v-if="this.phase_2 == 'league' &&  this.matches_phase_2 && 
							Object.keys(this.matches_phase_2).length > 0"
				:info="this.info_extra[1]"
				class="mt-6"
			/>
		</v-window-item>

		<!-- Goleadores Tab -->
        <transition name="fade" mode="out-in">
          <div v-if="selectedTab === 1 && sport === 'futbol'" class="tab-panel">
            <MaxScorers :idTournament="idT" />
          </div>
        </transition>
		<!-- Grilla de Partidos Tab -->
        <transition name="fade" mode="out-in">
		<div 
			v-if="(selectedTab === 2 && sport === 'futbol') || (selectedTab === 1 && sport !== 'futbol')"
			class="matches-grid"
		>
			<GrillaMatchesByDate :matches="matches_phase_1" />
			<!-- esto aca va a cambiar pq ahora tengo q ver si es liga tmb -->
			<GrillaMatchesByDatePlayoff
				v-if="this.phase_2 == 'playoff' &&  this.matches_phase_2 && 
					Object.keys(this.matches_phase_2).length > 0"
						:matches="matches_phase_2" />
			
			
			<GrillaMatchesByDateLeague v-if="this.phase_2 == 'league' && this.matches_phase_2 && 
				Object.keys(this.matches_phase_2).length > 0"
					:matches="matches_phase_2" />

		</div>
	</transition>

		<!-- Equipos Tab -->
        <transition name="fade" mode="out-in">
		<ShowTeamsOfTournament 
			v-if="(selectedTab === 3 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')" 
			:tournamentId="idT" 
		/>
	</transition>


    <transition name="fade" mode="out-in">
      <div
          v-if="(selectedTab === 4 && sport === 'futbol') || (selectedTab === 2 && sport !== 'futbol')"
          class="tab-panel"
      >
        <SuspensionsPlayer/>
      </div>
    </transition>

	</v-window>
	</div>
</div>
</template>

<script>

/*eslint-disable*/

import GrillaMatchesByDate from './GrillaMatchesByDate.vue';
import GrillaMatchesByDatePlayoff from './GrillaMatchesByDatePlayoff.vue';
import GrillaMatchesByDateLeague from './GrillaMatchesByDateLeague.vue';

import MaxScorers from './MaxScorers.vue';
import FixturePlayoff from './FixturePlayoff.vue';
import ShowTeamsOfTournament from './ShowTeamsOfTournament.vue';
import { useTournamentDetailsStore } from "@/store/tournamentDetails";
import axios from 'axios';
import { useAuthStore } from "@/store/auth";
import LeagueTableIsolated from './LeagueTableIsolated.vue';
import SuspensionsPlayer from './SuspensionsPlayer.vue';




export default {
	name: 'FixtureGroup',
	components: {
		GrillaMatchesByDate,
		MaxScorers,
		GrillaMatchesByDatePlayoff,
		FixturePlayoff,
		ShowTeamsOfTournament, 
		GrillaMatchesByDateLeague , 
		LeagueTableIsolated,
    SuspensionsPlayer
	},

	props: {
		idT: { required: true },
		info: { required: true },
		matches_phase_1: { required: true },
		matches_phase_2: { required: true },
		champion: { required: true },
		classified: { required: true },
		sport: { required: true } , 
		info_extra: {required: true}
	},

	data() {
	return {
		selectedTab: 0,
		tabs: [],
		groups: [],
		headers: [
		{
			key: 'name',
			title: this.sport === 'futbol' ? 'Equipo' : 'Jugador',
			width: '200px'
		},
		{ key: 'points', title: 'Pts', width: '70px' },
		{ key: 'partidos_jugados', title: 'PJ', width: '60px' },
		{ key: 'partidos_ganados', title: 'PG', width: '60px' },
		{ key: 'partidos_empatados', title: 'PE', width: '60px' },
		{ key: 'partidos_perdidos', title: 'PP', width: '60px' },
		{ 
			key: 'goles',
			title: this.sport === 'futbol' ? 'GF' : 'Sets G',
			width: '60px',
			class: 'hide-sm'
		},
		{
			key: 'goles_contra',
			title: this.sport === 'futbol' ? 'GC' : 'Sets P',
			width: '60px',
			class: 'hide-sm'
		},
		{
			key: 'diferencia_goles',
			title: this.sport === 'futbol' ? 'DG' : 'Dif',
			width: '60px'
		},
		],
		phase_2 : null
	};
	},

	mounted() {
		const detailsStore = useTournamentDetailsStore();
		console.log(this.sport);
		detailsStore.setSport(this.sport);
		this.groups = this.info;
		this.tabs = this.sport === 'futbol'
			? ['Grupos', 'Estadísticas', 'Partidos', 'Equipos','Sanciones']
			: ['Grupos', 'Partidos', 'Jugadores'];


		this.phase_2 ="playoff";
	},

	methods: {
	getTabIcon(index) {
		const icons = {
		0: '🏆',
		1: this.sport === 'futbol' ? '📊' : '📅',
		2: this.sport === 'futbol' ? '📅' : '👥',
		3: '👥',
      4: ''
		};
		return icons[index] || '📋';
	},
	calculateDifference(stats) {
		if (!stats) return 0;
		return stats.goles - stats.goles_contra;
	}
	}
};
</script>

<style scoped>
.fixture-container {
	max-width: 1200px;
	margin: 0 auto;
	padding: 1.5rem;
}

.tabs-navigation {
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	background: linear-gradient(to right, #1a237e, #0d47a1);
	padding: 1rem;
	border-radius: 12px;
	margin-bottom: 2rem;
	box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.tabs-container {
	display: flex;
	justify-content: center;
	gap: 0.5rem;
	width: 100%;
	flex-wrap: wrap;
}

.tab-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 0.75rem 1.5rem;
	border: none;
	border-radius: 8px;
	background: transparent;
	color: white;
	cursor: pointer;
	transition: all 0.3s ease;
	font-weight: 500;
	white-space: nowrap;
}

.tab-button:hover {
	background: rgba(255, 255, 255, 0.1);
	transform: translateY(-1px);
}

.tab-button.active {
	background: #2196f3;
	box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
}

.tab-icon {
	font-size: 1.25rem;
}

.content-wrapper {
	background: transparent;
	border-radius: 12px;
	overflow: visible;
}

:deep(.v-window) {
	overflow: visible;
}

:deep(.v-window__container) {
	overflow: visible;
}

.champion-banner {
	background: linear-gradient(135deg, #ffd700, #ffa000);
	border-radius: 12px;
	padding: 2rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	color: #1a237e;
}

.trophy-icon {
	font-size: 2.5rem;
}

.champion-info {
	text-align: center;
}

.champion-title {
	font-size: 1.5rem;
	margin: 0;
	opacity: 0.9;
}

.champion-name {
	display: block;
	font-size: 2rem;
	font-weight: 700;
	margin-top: 0.5rem;
}

.group-section {
	margin-bottom: 1.5rem;
}

.group-card {
	border-radius: 12px;
	overflow: hidden;
}

.group-header {
	background: linear-gradient(to right, #1a237e, #283593);
	color: white;
	padding: 1rem;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
}

.team-cell {
	min-width: 200px;
}

.team-info {
	display: flex;
	align-items: center;
	gap: 0.75rem;
}

.team-logo {
	width: 24px;
	height: 24px;
	object-fit: contain;
}

.team-icon {
	color: #1a237e;
	font-size: 20px;
}

.team-name {
	font-weight: 500;
}

.points-cell {
	font-weight: 600;
	color: #1a237e;
}

.qualified-row {
	background: linear-gradient(to right, rgba(33, 150, 243, 0.1), rgba(33, 150, 243, 0.2)) !important;
}

.matches-grid {
	display: grid;
	gap: 24px;
}

@media (max-width: 768px) {
	.fixture-container {
	padding: 1rem;
	}

	.tabs-navigation {
	padding: 0.75rem;
	}

	.tab-button {
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	}

	.hide-sm {
	display: none;
	}

	.team-cell {
	min-width: 150px;
	}

	.champion-banner {
	flex-direction: column;
	text-align: center;
	}
}

.grilla-component {
  width: 100%;
  max-width: 900px; /* Or any width that works for your design */
}
</style>