<template>
  <div class="main-container">
    <!-- Filtros y Ordenamiento -->
    <!-- Listado de Torneos -->
    <div class="tournaments-container">
      <!-- Tarjeta de "Agregar Torneo" -->
		<div 
		v-if="is_able" 
		class="tournament-card add-tournament"
		@click="goToCreateTournament"
		>
		<div class="tournament-header">
			<h2>Crear Nuevo Torneo</h2>
		</div>
		<div class="tournament-details" align="center">
			<v-img 
			:src="require('../assets/plus-add.png')"
			:width="100"
			aspect-ratio="4/3"
			cover
			class="add-icon"
			/>
			<p class="add-tournament-text">Haz clic aquí para crear un nuevo torneo</p>
		</div>
		</div>

      <!-- Tarjetas de Torneos -->
      <div
        v-for="tournament in filteredTournaments"
        :key="tournament.id"
        class="tournament-card"
        @click="goToTournament(tournament.id)"
      >
	<div class="tournament-header">
		<h2>{{ tournament.name_of_tournament }}</h2>
		<v-chip 
		v-if="tournament.champion"
		color="red"
		small
		class="status-chip"
		>
		Finalizado
		</v-chip>
		<v-chip 
		v-else-if="new Date(tournament.date_begin) > new Date()"
		color="grey"
		small
		class="status-chip"
		>
		Por comenzar
		</v-chip>
		<v-chip 
		v-else
		color="green"
		small
		class="status-chip"
		>
		Activo
		</v-chip>
		</div>
        
        <div class="tournament-details">
          <div class="detail-row">
            <v-icon icon="mdi-soccer" class="detail-icon" />
            <span>Deporte: <b>{{ tournament.sport }}</b></span>
          </div>
          
          <div class="detail-row">
            <v-icon icon="mdi-calendar" class="detail-icon" />
            <span>{{ formatDate(tournament.date_begin) }}</span>
          </div>
          
          <div class="detail-row">
            <v-icon icon="mdi-trophy" class="detail-icon" />
            <span>{{ tournament.schema_value }}</span>
          </div>
          
          <div v-if="tournament.champion" class="detail-row champion-row">
            <v-icon icon="mdi-crown" class="detail-icon champion-icon" />
            <span>Campeón: <b>{{ tournament.champion }}</b></span>
          </div>
          
          <div class="detail-row">
            <v-icon icon="mdi-flag" class="detail-icon" />
            <span>Fase actual: {{ tournament.current_phase }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Estado de Carga -->
    <div v-if="isLoading" class="loading-state">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
      <span class="loading-text">Cargando torneos...</span>
    </div>

    <!-- Estado Vacío -->
    <div v-if="!isLoading && filteredTournaments.length === 0" class="empty-state">
      <v-icon icon="mdi-trophy-outline" size="64" color="grey-lighten-1" />
      <h3>No hay torneos disponibles</h3>
      <p v-if="is_able">Haz clic en "Crear Nuevo Torneo" para agregar uno</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useTournamentStore } from "@/store/tournament";
import { useAuthStore } from "@/store/auth";

export default {
  name: 'TournamentList',

  setup() {
    const router = useRouter();
    const tournaments = ref([]);
    const isLoading = ref(false);
    const is_able = ref(useAuthStore().isAble);
    const searchQuery = ref('');
    const selectedSport = ref('');
    const sortBy = ref('date_begin');

    const sports = ['Fútbol', 'Tenis', 'Pádel'];
    const sortOptions = [
      { text: 'Fecha de inicio', value: 'date_begin' },
      { text: 'Nombre', value: 'name_of_tournament' },
    ];

    const filteredTournaments = computed(() => {
      let filtered = tournaments.value;

      if (searchQuery.value) {
        filtered = filtered.filter(t => 
          t.name_of_tournament.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      }

      if (selectedSport.value) {
        filtered = filtered.filter(t => 
          t.sport.toLowerCase() === selectedSport.value.toLowerCase()
        );
      }

      if (sortBy.value === 'date_begin') {
        filtered.sort((a, b) => new Date(a.date_begin) - new Date(b.date_begin));
      } else if (sortBy.value === 'name_of_tournament') {
        filtered.sort((a, b) => a.name_of_tournament.localeCompare(b.name_of_tournament));
      }

      return filtered;
    });

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('es-ES', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric' 
      });
    };

    const goToTournament = (id) => {
      router.push({ 
        name: 'TournamentDetails', 
        params: { id } 
      });
    };

    const goToCreateTournament = () => {
      router.push({ name: 'CreateTournament' });
    };

    const fetchTournamentData = async () => {
      isLoading.value = true;
      try {
        const tournamentStore = useTournamentStore();
        const response = await tournamentStore.dispatchGetTournaments();
        
        if (response.success) {
          tournaments.value = response.content;
        } else {
          console.error("Error al obtener los torneos:", response.error);
        }
      } catch (error) {
        console.error("Error al cargar los torneos:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchTournamentData();
    });

    return {
      tournaments,
      isLoading,
      is_able,
      searchQuery,
      selectedSport,
      sortBy,
      sports,
      sortOptions,
      filteredTournaments,
      formatDate,
      goToTournament,
      goToCreateTournament
    };
  }
};
</script>

<style scoped>
/* Estilos mejorados */
.filters-container {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  padding: 0 20px;
}

.search-input, .filter-select {
  flex: 1;
}

.add-tournament-text {
  color: #3b82f6;
  margin-top: 8px;
  font-weight: 500;
}

.status-chip {
  margin-left: 8px;
}
  .main-container {
    font-family: 'Arial', sans-serif;
    margin-top: 2%;
    padding: 0 20px;
  }
  
  .tournaments-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 20px;
  }
  
  .tournament-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    overflow: hidden;
  }
  
  .tournament-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.2);
  }
  
  .tournament-header {
    background: linear-gradient(to right, #1a237e, #0d47a1);
    color: white;
    padding: 16px;
  }
  
  .tournament-header h2 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .tournament-details {
    padding: 16px;
  }
  
  .detail-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
    color: #4b5563;
  }
  
  .detail-icon {
    color: #6b7280;
    font-size: 20px;
  }
  
  .champion-row {
    background: linear-gradient(to right, #fef3c7, #fde68a);
    margin: 8px -16px;
    padding: 8px 16px;
  }
  
  .champion-icon {
    color: #d97706;
  }
  
  .add-tournament {
  background-color: #3b82f6; /* Fondo azul vibrante */
  border: none; /* Sin borde */
  min-height: 200px; /* Altura reducida */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 12px; /* Bordes redondeados */
}

.add-tournament:hover {
  background-color: #2563eb; /* Azul más oscuro al pasar el mouse */
}

.add-tournament .tournament-header {
  background: transparent; /* Sin fondo en el header */
  padding: 12px;
}

.add-tournament h2 {
  color: white; /* Texto blanco */
  font-size: 1.5rem; /* Texto más grande */
  margin: 0;
}

.add-tournament .tournament-details {
  padding: 12px;
}

.add-tournament-text {
  color: white; /* Texto blanco */
  margin-top: 8px;
  font-weight: 500;
  font-size: 1rem; /* Texto más grande */
}

.add-icon {
  opacity: 0.9;
  transition: opacity 0.3s ease;
  filter: brightness(0) invert(1); /* Convertir ícono a blanco */
}

.add-tournament:hover .add-icon {
  opacity: 1;
}
.add-icon {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.add-tournament:hover .add-icon {
  opacity: 1;
}
  
  .add-icon {
    opacity: 0.6;
    transition: opacity 0.2s ease;
  }
  
  .add-tournament:hover .add-icon {
    opacity: 1;
  }
  
  .loading-state, .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    gap: 16px;
    text-align: center;
  }
  
  .loading-text {
    color: #6b7280;
    margin-top: 16px;
  }
  
  .empty-state h3 {
    color: #374151;
    margin: 16px 0 8px;
  }
  
  .empty-state p {
    color: #6b7280;
  }
  
  @media (max-width: 640px) {
    .tournaments-container {
      grid-template-columns: 1fr;
      padding: 16px;
    }
  
    .tournament-card {
      width: 100%;
    }
  }
  </style>