<template>
    <div class="landing-page">
      <!-- Header -->
      <header class="header">
        <div class="container">
          <h1 class="logo">Torneus</h1>
          <nav class="nav">
            <a href="#about" class="nav-link">Quiénes Somos</a>
            <a href="#features" class="nav-link">Qué Hacemos</a>
            <a href="#contact" class="nav-link">Contacto</a>
            <button class="login-button" @click="redirectToLogin">Entrar al Sistema</button>
          </nav>
        </div>
      </header>
  
      <!-- Hero Section -->
      <section class="hero">
        <div class="container">
          <div class="hero-content">
            <h2 class="hero-title">Gestiona tus torneos de fútbol, pádel y tenis</h2>
            <p class="hero-text">
              Simplifica la organización de torneos, sigue estadísticas en tiempo real y ofrece una experiencia única a tus usuarios.
            </p>
            <button class="hero-button" @click="redirectToLogin">Comenzar ahora</button>
          </div>
        </div>
      </section>
  
      <!-- About Section -->
      <section id="about" class="about">
        <div class="container">
          <h2 class="section-title">Quiénes Somos</h2>
          <p class="about-text">
            En <strong>Torneus</strong>, somos apasionados por los deportes y la tecnología. Creamos software de gestión de torneos para fútbol, pádel y tenis, ayudando a organizadores y jugadores a disfrutar de una experiencia única y profesional.
          </p>
        </div>
      </section>
  
      <!-- Features Section -->
      <section id="features" class="features">
        <div class="container">
          <h2 class="section-title">Qué Hacemos</h2>
          <div class="features-grid">
            <div class="feature">
              <h3 class="feature-title">Multiples Deportes</h3>
              <p class="feature-text">Gestiona torneos de fútbol, pádel, tenis y más.</p>
            </div>
            <div class="feature">
              <h3 class="feature-title">Tipos de Torneos</h3>
              <p class="feature-text">Liga, Liga + Playoff, Grupo + Playoff, Playoff.</p>
            </div>
            <div class="feature">
              <h3 class="feature-title">Estadísticas Detalladas</h3>
              <p class="feature-text">Seguimiento de equipos, jugadores y torneos anteriores.</p>
            </div>
            <div class="feature">
              <h3 class="feature-title">Pre-Clasificación</h3>
              <p class="feature-text">Posibilidad de pre-clasificar equipos.</p>
            </div>
            <div class="feature">
              <h3 class="feature-title">Tiempo Real</h3>
              <p class="feature-text">Los usuarios pueden ver la información en tiempo real.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Contact Section -->
      <section id="contact" class="contact">
        <div class="container">
          <h2 class="section-title">Contacto</h2>
          <div class="contact-info">
            <p><strong>Email:</strong> torneusnet@gmail.com</p>
            <p><strong>Teléfono:</strong> 1123939178</p>
            <p><strong>Instagram:</strong> <a href="https://instagram.com/torneus.app" target="_blank">@torneus.app</a></p>
            <p><strong>Sitio Web:</strong> <a href="https://torneus.net" target="_blank">torneus.net</a></p>
          </div>
        </div>
      </section>
  
      <!-- Footer -->
      <footer class="footer">
        <div class="container">
          <p class="footer-text">&copy; 2023 Torneus. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      redirectToLogin() {
        // Redirigir al sistema de login
        this.$router.push({name: 'home'});
      },
      smoothScroll(target) {
        const element = document.querySelector(target);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    mounted() {
      // Agregar event listeners para los enlaces del menú
      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach(link => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          const target = link.getAttribute('href');
          this.smoothScroll(target);
        });
      });
    }
  };
  </script>
  
  <style scoped>
  /* Estilos generales */
  body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #2c3e50; /* Azul marino para el texto principal */
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header */
  .header {
    background-color: #1e3a8a; /* Azul más intenso y moderno */
    color: #fff; /* Blanco */
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky; /* Hacemos el header fijo */
    top: 0; /* Lo pegamos arriba */
    z-index: 1000; /* Aseguramos que esté por encima del contenido */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  }
  
  .logo {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .nav {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .nav-link {
    color: #fff; /* Blanco */
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease; /* Transición suave */
  }
  
  .nav-link:hover {
    color: #a0c4ff; /* Azul claro al pasar el mouse */
  }
  
  .login-button {
    background-color: #3b82f6; /* Azul vibrante */
    color: #fff; /* Blanco */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease; /* Transición suave */
    margin-left: auto; /* Mueve el botón a la derecha */
  }
  
  .login-button:hover {
    background-color: #2563eb; /* Azul más oscuro al pasar el mouse */
  }
  
  /* Hero Section */
  .hero {
    background: linear-gradient(135deg, #3b82f6, #1e3a8a); /* Degradado moderno */
    color: #fff; /* Blanco */
    padding: 120px 0; /* Más padding para mayor impacto */
    text-align: center;
  }
  
  .hero-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .hero-text {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .hero-button {
    background-color: #fff; /* Blanco */
    color: #3b82f6; /* Azul vibrante */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */
  }
  
  .hero-button:hover {
    background-color: #f0f4f8; /* Gris muy claro al pasar el mouse */
    color: #2563eb; /* Azul más oscuro */
  }

  
  /* About Section */
  .about {
    padding: 80px 0;
    text-align: center;
    background-color: #fff; /* Blanco */
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 40px;
    color: #1e3a8a; /* Azul más intenso */
  }
  
  .about-text {
    font-size: 1.1rem;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    color: #2c3e50; /* Azul marino */
  }
  
  /* Features Section */
  .features {
    padding: 80px 0;
    text-align: center;
    background-color: #f8fafc; /* Gris muy claro para el fondo */
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .feature {
    background-color: #fff; /* Blanco */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .feature-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #1e3a8a; /* Azul más intenso */
  }
  
  .feature-text {
    font-size: 1rem;
    color: #666; /* Gris oscuro */
  }
  
  /* Contact Section */
  .contact {
    padding: 80px 0;
    text-align: center;
    background-color: #fff; /* Blanco */
  }
  
  .contact-info {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #2c3e50; /* Azul marino */
  }
  
  .contact-info a {
    color: #3b82f6; /* Azul vibrante */
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  /* Footer */
  .footer {
    background-color: #1e3a8a; /* Azul más intenso */
    color: #fff; /* Blanco */
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-text {
    margin: 0;
    font-size: 0.9rem;
  }
  </style>