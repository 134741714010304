<template>
	<div class="create-tournament-form">
		<h2>Create Tournament</h2>
		<form @submit.prevent="submitForm">
			<!-- Nombre del Torneo -->
			<div class="form-group">
				<label for="name">Tournament Name:</label>
				<input type="text" v-model="tournament.name" id="name" required />
			</div>

			<!-- Selección de Deporte -->
			<div class="form-group">
				<label for="sport">Sport:</label>
				<select v-model="tournament.sport" id="sport" @change="handleSportChange">
					<option value="futbol">Futbol</option>
					<option value="tenis">Tenis</option>
					<option value="padel">Padel</option>
				</select>
			</div>

			<!-- Fechas de Inicio y Fin -->
			<div class="form-row">
				<div class="form-group">
					<label for="startDate">Start Date:</label>
					<input type="date" v-model="tournament.startDate" id="startDate" />
				</div>
				<div class="form-group">
					<label for="endDate">End Date:</label>
					<input type="date" v-model="tournament.endDate" id="endDate" />
				</div>
			</div>

			<!-- Número de Equipos -->
			<div class="form-group">
				<label for="teams">Number of Teams:</label>
				<input type="number" v-model="tournament.numOfTeams" id="teams" required min="2" />
			</div>

			<!-- Formato del Torneo -->
			<div class="form-group">
				<label>Tournament Format:</label>
				<div class="format-options">
					<div
						v-for="option in filteredFormatOptions"
						:key="option.value"
						:class="['format-option', { selected: tournament.format === option.value }]"
						@click="selectFormat(option.value)"
					>
						<span>{{ option.label }}</span>
					</div>
				</div>
			</div>

			<!-- Ida y Vuelta (Solo para Fútbol) -->
			<div class="form-group" v-if="tournament.sport === 'futbol' && tournament.format !== 'playoff' && tournament.format !== ''">
				<label for="cross">Partido ida y vuelta:</label>
				<v-row>
					<v-col>No</v-col>
					<v-switch
						v-model="cross"
						false-value="ida"
						true-value="ida_vuelta"
						inset
						color="indigo"
					></v-switch>
					<v-col>Si</v-col>
				</v-row>
			</div>

			<!-- Número de Grupos (Solo para Formato de Grupos) -->
			<div v-if="tournament.format === 'group'" class="form-group">
				<label for="groups">Number of Groups:</label>
				<input type="number" v-model="tournament.groups" id="groups" required min="2" />
			</div>

			<!-- Costos -->
			<div class="form-row">
				<div class="form-group">
					<label for="amountCosts">Costo inscripción:</label>
					<input type="number" v-model="tournament.amountInscription" id="amountCosts" min="0" />
				</div>
				<div class="form-group">
					<label for="amount">Costo partido:</label>
					<input type="number" v-model="tournament.amountMatch" id="amount" min="0" />
				</div>
			</div>

			<!-- Equipos Clasificados (Solo para Formatos con Playoff) -->
			<div v-if="tournament.hasTwoPhases" class="form-group">
				<label for="qualifiedTeams">Cantidad de equipos que clasifican a los playoff (sumando todos los grupos):</label>
				<input type="number" v-model="tournament.qualifiedTeams" id="qualifiedTeams" required min="1" />
			</div>

			<!-- Número de Grupos (Solo para Formatos de Grupos) -->
			<div v-if="tournament.isGroup" class="form-group">
				<label for="qualifiedTeams">Número de grupos:</label>
				<input type="number" v-model="tournament.numberOfGroups" id="qualifiedTeams" required min="1" />
			</div>

			<!-- Selección de Equipos -->
			<h3>Choose Team:</h3>
			<v-autocomplete
				v-model="newTeamName"
				:items="filteredTeams"
				item-value="name"
				item-title="name"
				label="Select team"
				clearable
				@input="filterTeams"
				class="custom-autocomplete"
				@keydown.enter.prevent="addTeam"
			/>

			<button type="button" class="full-width-button add-team-button" @click="addTeam">
				Add Team
			</button>
			<br>

			<!-- Lista de Equipos -->
			<div v-if="tournament.teams.length" class="teams-list">
				<h3>Teams:</h3>
				<div v-if="showPreclassification" class="preclassification-notice">
					<div class="info-box">
						<span class="info-icon">ℹ️</span>
						<p>Asignar números de preclasificación a los equipos (1 a N, deben ser consecutivos). Dejar como 0 para equipos sin preclasificación.</p>
						<div v-if="preclassificationError" class="error-message">
							{{ preclassificationError }}
						</div>
					</div>
				</div>
				<ul>
					<li v-for="(team, index) in tournament.teams" :key="team.id" class="team-item">
						<div class="team-info">
							<span class="team-name">{{ team.name }}</span>
							<div v-if="showPreclassification" class="preclassification-controls">
								<label :for="'preclassification-' + index">Preclassification:</label>
								<input
									type="number"
									:id="'preclassification-' + index"
									v-model="team.preclassification"
									min="0"
									:max="tournament.teams.length"
									class="preclassification-input"
									placeholder="0 = no preclassification"
									@change="validatePreclassification(index)"
									:class="{ 'error': preclassificationError }"
								/>
							</div>
						</div>
						<button type="button" @click="removeTeam(index)" class="remove-button">Remove</button>
					</li>
				</ul>
			</div>

			<!-- Botón de Crear Torneo -->
			<button 
				type="submit" 
				class="full-width-button create-tournament-button"
				:disabled="showPreclassification && !isPreclassificationValid"
				:class="{ 'disabled': showPreclassification && !isPreclassificationValid }"
			>
				Create Tournament
			</button>
		</form>
	</div>
</template>

<script>
import axios from 'axios';
import { useTeamStore } from "@/store/team";
import { useAuthStore } from "@/store/auth";

export default {
	data() {
		return {
			tournament: {
				name: '',
				startDate: '',
				endDate: '',
				teams: [],
				format: '',
				groups: null,
				qualifiedTeams: null,
				hasTwoPhases: false,
				isGroup: false,
				numberOfGroups: 0,
				numOfTeams: 0,
				newTeams: [],
				sport: 'futbol',
				amountInscription: null,
				amountMatch: null,
			},
			newTeamName: '',
			filteredTeams: [],
			allTeams: [], 
			formatOptions: [
				{ label: 'League', value: 'league', image: 'league-icon.png' },
				{ label: 'Playoff', value: 'playoff', image: 'playoff-icon.png' },
				{ label: 'Group + Playoff', value: 'group+playoff', image: 'group-playoff-icon.png' },
				{ label: 'League + Playoff', value: 'league+playoff', image: 'league-playoff-icon.png' },
				{ label: 'Group + League', value: 'group+league', image: 'group-league-icon.png' },
			],
			filteredFormatOptions: [],
			cross: 'ida',
		};
	},

	computed: {
		showPreclassification() {
			return ['playoff', 'group+playoff', 'group+league'].includes(this.tournament.format);
		}, 
		preclassificationError() {
			if (!this.showPreclassification) return '';
			
			// Obtener números de preclasificación mayores a 0
			const preNumbers = this.tournament.teams
				.map(team => team.preclassification)
				.filter(num => num > 0)
				.sort((a, b) => a - b);
				
			if (preNumbers.length === 0) return '';

			// Verificar números únicos
			const uniqueNumbers = new Set(preNumbers);
			if (uniqueNumbers.size !== preNumbers.length) {
				return 'Error: Las preclasificaciones deben ser unicas';
			}

			// Verificar secuencia consecutiva
			const min = Math.min(...preNumbers);
			const max = Math.max(...preNumbers);
			if (min !== 1) {
				return 'Error: Preclasificacion debe comenzar en 1';
			}
			
			if (max - min + 1 !== preNumbers.length) {
				return 'Error: Las preclasificaciones deben ser numeros consecutivos';
			}

			return '';
		},
		isPreclassificationValid() {
			// También quiero chequear que sean los preclasificados una potencia de 2 
			var is_potencia_dos = this.is_potencia_dos();
			console.log("potencia de 2 " + is_potencia_dos);
			return !this.preclassificationError && is_potencia_dos;
		}
	},

	methods: {
		is_potencia_dos() {
			// Obtener números de preclasificación mayores a 0
			var preNumbers = this.tournament.teams
				.map(team => team.preclassification);

			const maxNumber = Math.max(...Object.values(preNumbers));

			const log2 = Math.log2(maxNumber);    
			// Si el logaritmo es un número entero, es potencia de 2
			return Math.floor(log2) === log2;
		}, 

		validatePreclassification(index) {
			const team = this.tournament.teams[index];
			let value = parseInt(team.preclassification) || 0;
			
			// Asegurar que sea 0 o positivo
			if (value < 0) value = 0;
			
			// Si es mayor que la cantidad de equipos, resetear a 0
			if (value > this.tournament.teams.length) {
				value = 0;
			}
        
			team.preclassification = value;
		},

		async fetchTeams() {
			const teamStore = useTeamStore();
			const response = await teamStore.dispatchGetTeams();
			this.allTeams = response.content;
			this.filteredTeams = this.allTeams;
		},

		filterTeams() {
			if (!this.newTeamName) {
				return;
			}
			const query = this.newTeamName.toLowerCase();
			if (query) {
				this.filteredTeams = this.allTeams.filter(team =>
					team.name.toLowerCase().includes(query)
				);
			} else {
				this.filteredTeams = this.allTeams;
			}
		},

		showAllTeams() {
			this.filteredTeams = this.allTeams;
		},

		addTeam() {
			const selectedTeam = this.allTeams.find(
				team => team.name === this.newTeamName
			);
			if (selectedTeam && !this.tournament.teams.some(t => t.id === selectedTeam.id)) {
				const teamWithPreclassification = {
					...selectedTeam,
					preclassification: 0
				};
				this.tournament.teams.push(teamWithPreclassification);
				this.newTeamName = '';
				this.filteredTeams = this.allTeams;
			}
		},

		removeTeam(index) {
			this.tournament.teams.splice(index, 1);
		},

		handleSportChange() {
			const sport = this.tournament.sport;
			if (sport === 'tenis' || sport === 'padel') {
				this.filteredFormatOptions = this.formatOptions.filter(option =>
					!option.value.includes('league')
				);
			} else {
				this.filteredFormatOptions = this.formatOptions;
			}
			this.tournament.format = '';
		},

		async submitForm() {
			if (this.tournament.teams.length !== this.tournament.numOfTeams) {
				alert(`La cantidad de equipos seleccionados (${this.tournament.teams.length}) no coincide con el número ingresado (${this.tournament.numOfTeams}).`);
				return;
			}

			if (this.tournament.format.includes('group')) {
				const teamsPerGroup = this.tournament.numOfTeams / this.tournament.numberOfGroups;
				if (!Number.isInteger(teamsPerGroup)) {
					alert(`La cantidad de equipos (${this.tournament.numOfTeams}) no se puede dividir en grupos iguales de ${this.tournament.numberOfGroups} grupos.`);
					return;
				}

				const qualifiedPerGroup = this.tournament.qualifiedTeams / this.tournament.numberOfGroups;
				if (!Number.isInteger(qualifiedPerGroup)) {
					alert(`La cantidad de equipos clasificados (${this.tournament.qualifiedTeams}) no se puede dividir en partes iguales entre ${this.tournament.numberOfGroups} grupos.`);
					return;
				}
			}

			let number_of_phases = 1;
			if (this.tournament.format === "group+playoff" || this.tournament.format === "league+playoff"
				|| this.tournament.format === "group+league") {
				if (this.tournament.qualifiedTeams <= 1) {
					alert(`La cantidad de equipos clasificados a la proxima ronda tiene q ser mayor que 1`);
					return;
				}
				number_of_phases = 2;
			}

			// Crear array de objetos que mantenga la relación id-preclasificación
			let teamsWithPreclassification = this.tournament.teams.map(team => ({
				id: team.id,
				preclassification: team.preclassification || 0
			}));

			// Ordenar por preclasificación (primero los preclasificados, ordenados, luego los 0)
			teamsWithPreclassification.sort((a, b) => {
				if (a.preclassification === 0 && b.preclassification === 0) return 0;
				if (a.preclassification === 0) return 1;
				if (b.preclassification === 0) return -1;
				return a.preclassification - b.preclassification;
			});

			// Ahora separamos en los dos arrays manteniendo el orden
			let teams_ids = teamsWithPreclassification.map(team => team.id);
			let ids_zero = teamsWithPreclassification.map(team => team.preclassification);

			let preclassification_list = [];
			if (this.showPreclassification) {
				preclassification_list = ids_zero;
			} else {
				preclassification_list = null;
			}

			const params = {
				"name_of_tournament": this.tournament.name,
				"date_begin": this.tournament.startDate,
				"date_end": this.tournament.endDate,
				"number_of_stadiums": "3",
				"time_range": "TODO",
				"sport": this.tournament.sport,
				"number_of_phases": number_of_phases,
				"schema_value": this.tournament.format,
				"match_time": "60",
				"total_by_subscription_by_team": this.tournament.amountInscription,
				"total_by_match_by_team": this.tournament.amountMatch,
				"schema_conf": {
					"f1": {
						"preclasificados": this.tournament.teams.filter(t => t.preclassification > 0).length,
						"with_preclasifications_number": this.showPreclassification,
						"cross": this.cross,
						"classification_strategy": "primeros_vs_ultimos",
						"cuantos_clasifican": this.tournament.qualifiedTeams,
						"cant_groups": this.tournament.numberOfGroups,
						"teams_by_group": this.tournament.numOfTeams / this.tournament.numberOfGroups,
					}
				},
				"teams": teams_ids, 
				"preclasif" : preclassification_list
			};

			const token = useAuthStore().token;

			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + 'tournament', params, {
				headers: {
					'Authorization': `Bearer ${useAuthStore().decryptPassword(token)}`,
					'Content-Type': 'application/json'
				}
			});
			
			if (response.status === 200) {
				alert("¡Torneo creado exitosamente!");
				this.resetForm();
			}
		},

		selectFormat(format) {
			this.tournament.format = format;
			this.tournament.hasTwoPhases = format.includes('+');
			this.tournament.isGroup = format.includes('group');
		},

		resetForm() {
			this.tournament = {
				name: '',
				startDate: '',
				endDate: '',
				teams: [],
				format: '',
				groups: null,
				qualifiedTeams: null,
				hasTwoPhases: false,
				isGroup: false,
				numberOfGroups: 0,
				numOfTeams: 0,
				sport: 'futbol',
				amountInscription: null,
				amountMatch: null,
			};
			this.newTeamName = '';
			this.filteredTeams = [];
			this.cross = 'ida';
		}
	},

	mounted() {
		this.fetchTeams();
		this.handleSportChange();
	},
};
</script>

<style scoped>
.create-tournament-form {
	max-width: 800px;
	margin: auto;
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
	margin-bottom: 20px;
}

label {
	display: block;
	font-weight: bold;
	margin-bottom: 5px;
}

input, select {
	width: 100%;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.format-options {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.format-option {
	padding: 10px;
	border: 2px solid #ddd;
	border-radius: 8px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.format-option.selected {
	background-color: #4caf50;
	color: white;
	border-color: #4caf50;
}

button.submit-button {
	background-color: #4caf50;
	color: white;
	border: none;
	padding: 10px 20px;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 10px;
}

button.submit-button:hover {
	background-color: #45a049;
}

.teams-list ul {
	list-style-type: none;
	padding-left: 0;
}

.team-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	background-color: #f8f9fa;
	border-radius: 8px;
	margin-bottom: 10px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.team-info {
	display: flex;
	align-items: center;
	gap: 20px;
	flex: 1;
}

.team-name {
	font-weight: 500;
	min-width: 150px;
}

.preclassification-controls {
	display: flex;
	align-items: center;
	gap: 10px;
}

.preclassification-input {
	width: 80px;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 4px;
	text-align: center;
}

.remove-button {
	background-color: #dc3545;
	color: white;
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.2s;
}

.remove-button:hover {
	background-color: #c82333;
}

.info-box {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 12px;
	background-color: #e3f2fd;
	border-radius: 6px;
	margin-bottom: 15px;
}

.info-icon {
	font-size: 20px;
}

.preclassification-notice p {
	margin: 0;
	color: #0d47a1;
}

.custom-autocomplete .v-list-item--active {
	background-color: #A8E6CF !important;
	color: black;
}

.custom-autocomplete .v-autocomplete__content {
	max-height: 200px;
	overflow-y: auto;
}

.error-message {
	color: #dc3545;
	margin-top: 8px;
	font-weight: 500;
}

.submit-button.disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

.submit-button:disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

.form-row {
	display: flex;
	gap: 20px;
}

.form-row .form-group {
	flex: 1;
}

.add-team-button {
	background-color: #3b82f6; /* Azul vibrante */
	color: white;
	border: none;
	padding: 12px 24px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.3s ease, transform 0.2s ease;
	margin-top: 10px;
	width: 100%;
}

.add-team-button:hover {
	background-color: #2563eb; /* Azul más oscuro al pasar el mouse */
	transform: translateY(-2px);
}

.add-team-button:active {
	transform: translateY(0);
}

/* Estilos para el botón "Create Tournament" */
.create-tournament-button {
	background-color: #1e3a8a; /* Azul intenso */
	color: white;
	border: none;
	padding: 16px 32px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 18px;
	font-weight: 600;
	transition: background-color 0.3s ease, transform 0.2s ease;
	margin-top: 20px;
	width: 100%;
}

.create-tournament-button:hover {
	background-color: #1d4ed8; /* Azul más claro al pasar el mouse */
	transform: translateY(-2px);
}

.create-tournament-button:active {
	transform: translateY(0);
}

.create-tournament-button.disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

.create-tournament-button:disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

.full-width-button {
	background-color: #3b82f6; /* Azul vibrante */
	color: white;
	border: none;
	padding: 16px 24px;
	border-radius: 8px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	transition: background-color 0.3s ease, transform 0.2s ease;
	width: 100%;
	margin-top: 10px;
	text-align: center;
}

.full-width-button:hover {
	background-color: #2563eb; /* Azul más oscuro al pasar el mouse */
	transform: translateY(-2px);
}

.full-width-button:active {
	transform: translateY(0);
}

/* Estilos específicos para el botón "Create Tournament" */
.create-tournament-button.disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

.create-tournament-button:disabled {
	background-color: #cccccc;
	cursor: not-allowed;
	opacity: 0.7;
}

</style>